import { Component, OnInit, ViewChild, ChangeDetectorRef, Inject } from '@angular/core';
import {
  IWorkListModel, WorkListSearch, WorkListSearchDropdowns, IBasicFilter, SelectionModel,
  CaseInUseOutputModel, IMainFilter, IDateRange, StringSelectionModel, IAssignableUsers,
  IUserPermissionModel, IUserRolesPermissions, IDispenseAssessmentModel
} from '../models/Iworklist-model';
import {
  UITKTableDataSource,
  UITKTableSortDirective,
  UITKSortDirection,
  IUITKColumnState,
} from '@uitk/angular';


import { WorklistService } from '../services/worklist.service';
import { StatusesForDraftAssessment, EditAssessmentPermittedStatuses,ReadOnlyOpasStatuses, Constants, PageTitle} from '../Constants/WorkListConstants';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as enums from '../common/Enums';
import { INotificationService, notificationServiceToken } from '../shared/notification.service';
import { BasicSearchComponent } from '../basic-search/basic-search.component';
import { GlobalSearchComponent } from '../global-search/global-search.component';
import { AdvancedSearchComponent } from '../advanced-search/advanced-search.component';
import { Roles, Permissions } from '../common/Enums';
import { ActivityDialogComponent } from '../activity-dialog/activity-dialog.component';

@Component({
  selector: 'app-worklist',
  templateUrl: './worklist.component.html',
  styleUrls: ['./worklist.component.scss']
})
export class WorklistComponent implements OnInit {

  workList: IWorkListModel[] = [];
  workListTableHeader = [
    { name: 'Hospital', id: 'hospitalName' },
    { name: 'Patient Name', id: 'patientName' },
    { name: 'Admit', id: 'admitDate' },
    { name: 'In Use', id: 'inUse' },
    { name: 'Status', id: 'status' },
    { name: 'Due', id: 'dueOn' },
    { name: 'Assessment Type', id: 'assessmentType' },
    { name: 'Payer', id: 'payorName' },
    { name: 'Case Manager', id: 'caseManager' },
    { name: 'Attending', id: 'attendingPhysician' },
    { name: 'Assigned To', id: 'assignedToName' },
    { name: 'Activities', id: 'activityShortName' },
    { name: 'Assessment ID', id: 'assessmentId' },
    { name: 'Notifications', id: 'notificationMessage' }
  ];

  worklistSearchModel: WorkListSearch = {
    hospital: null,
    patientFirstName: null,
    patientLastName: null,
    inUse: null,
    status: null,
    reviewType: null,
    assessmentType: null,
    payer: null,
    attendingPhysician: null,
    caseManager: null,
    activities: null,
    assessmentId: null,
    accountNumber: null,
    assignedTo: null,
    healthSystem: null,
    isActiveClients: false,
    isIncludeClosed: false,
    isIncludeDeleted: false,
    fromAdmitDate: null,
    toAdmitDate: null,
    fromDischargeDate: null,
    toDischargeDate: null
  }

  workListSearchDropdownData: WorkListSearchDropdowns = {
    hospitalList: [],
    inUseList: [],
    statusList: [],
    reviewTypeList: [],
    assessmentTypeList: [],
    attendingPhysicianList: [],
    caseManagerList: [],
    activitiesList: [],
    assignedToList: [],
    healthSystemList: [],
    healthSystemHospitalList: [],
    payerList: []
  };

  activityDetailsList: any[] = [];

  public isSearchPerformed: boolean = false;
  private startIndex: number = 0;
  private endIndex: number = 0;
  private pageSize: number = 25;
  public loadingText: string = "Loading...";
  public displayIndicator: boolean = false;
  public openPanel: boolean = false;
  public openadvancedPanel: boolean = true;
  private recentReviewTimeSpan: number = +(environment.myRecentReviewsTimeFrame);
  public maxCaseInUseCount: number = +(environment.maxCaseInUseCount);
  public showCaseInUseDialog: boolean = false;
  public sorting: string = 'hospitalName DESC';
  public scrollTriggered = false;
  public recordCount: number;
  public isAcceptingCases: boolean = false;
  public showIsAcceptingCasesToggle: boolean = false;
  public currentInUse:string="";
  displayWarning: boolean = false;
  showInUseWarningDialog: boolean = false;
  public currentRecord:IWorkListModel;
  public mnrButtonEnabled: boolean = true;
  public showActivityDialog: boolean = false;
  public currentAssessmentId: number;
  public pageTitle: string = PageTitle;
  public isEnableF245991: boolean = false;

  //'New Case'
  mainFilterData: IMainFilter = {
    globalFilter: {
      searchField: '', createdDateRange: { fromDate: '', toDate: '' }, searchType: ''
    },
    basicFilter: {
      assessmentTypeIds: "",status:"", hospitalId: 0,assignedTo:"", attendingPhysicianId: 0, patientFirstName: "", patientLastName: "", caseManager: "",
      payerName: "", inUse: '', recentReviewTimeSpan: this.recentReviewTimeSpan, accountNumber: '', isActiveClients: false,
      isIncludeClosed: false, isIncludeDeleted: false,
      fromAdmitDate: '', toAdmitDate: '', fromDischargeDate: '', toDischargeDate: '', healthSystem: 0, activities: '',
      isGlobalRestriction: false,isGlobalRestrictionPR: false
    },
    advancedFilter: {
      initialOrderId: 0, intakeSourceId: 0, memberId: '', ocgId: 0, recommendationId: 0, isOpenActivityOnly: false, taskStatus: '', contactMethod: '', contactReason: '', contactOutcome: '', contactResponse: '', contactStatus: '',
      flrCriteriaStatus: 0, isDischargeSummaryOnly: false, isPayerRefusalOnly: false
    },
    skipCount: this.startIndex, maxCount: this.pageSize, sorting: this.sorting
  };

  workListTableData = new UITKTableDataSource<IWorkListModel>([]);
  tempData = new UITKTableDataSource<IWorkListModel>([]);
  initialWorkListData: IWorkListModel[] = [];
  @ViewChild('sortTable') uitkTableSort: UITKTableSortDirective;
  selectedValue: any;
  closeButtonText: string = 'Close';

  public assignableUsers: StringSelectionModel[] = [];
  public selectedUser: StringSelectionModel;
  assignedToUsers: StringSelectionModel[] = [];


  normalDialogModal = {
    show: false,
  };
  activityDialog = {
    show: false
  };
  tempassessmentid: number;
  userRoles: string[] = [];
  rolesEnum: typeof enums.Roles = enums.Roles;
  public errorMessagesList: Array<string> = [];
  public errorMessage: string = "";
  userPermissions: IUserPermissionModel[] = [];
  userRolesPermissions: IUserRolesPermissions = null;
  dispenseAssessmentModel : IDispenseAssessmentModel = null;
  unlockPermissionGranted: boolean = false;
  caseOpenInCaseViewGranted: boolean = false;
  caseOpenInCaseIntakeGranted: boolean = false;
  pageReady: boolean = false;
  showPatientNameAsLink: boolean = false;
  hasMyNextReviewAccess: boolean = false;
  hasWorklistAccess: boolean = false;
  isValidMyLockCasesFilter: boolean = false;
  constructor(private changeDetect: ChangeDetectorRef, private workListService: WorklistService, @Inject(notificationServiceToken)
  public notificationService: INotificationService) { }

  @ViewChild(BasicSearchComponent, { static: false }) basicSearch: BasicSearchComponent;
  @ViewChild(GlobalSearchComponent, { static: true }) globalSearch: GlobalSearchComponent;
  @ViewChild(AdvancedSearchComponent, { static: false }) advancedSearch: AdvancedSearchComponent;

  ngOnInit(): void {
    this.setIsEnableF245991("EnableF245991");
    this.getAllUserRoles();
    this.getUserIdentityRolesPermissions().then((hasAccess) => {
      if (hasAccess) {
        this.loadPersistedSearchPanel();
        this.loadPersistedAdvancedPanel();
        this.globalSearch?.setInitialFilter();
        this.getGlobalSearchFilterValues();
        this.workListTableData.setSortState({ column: 'hospitalName', direction: UITKSortDirection.DESC });
        this.LoadAssignableUsers();
        this.retrieveIsCurrentUserAcceptingCases();
      }
    })
    .catch(error => console.log(error));
  }

  ngAfterViewInit() {    
      this.changeDetect.detectChanges();
      // sets initial sort direction
      this.uitkTableSort.setDirection({ column: 'hospitalName', direction: UITKSortDirection.DESC });
      this.mainFilterData.globalFilter.searchType = this.globalSearch.globalFilter.searchType;
  }

  onAssignToUsersDropdownChange($event: StringSelectionModel) {
    this.selectedUser.label = $event.label;
    this.selectedUser.value = $event.value;
  }

  openActivityDialog(worklistRecord: IWorkListModel) {
    this.currentAssessmentId = worklistRecord.assessmentId;
    this.activityDetailsList = worklistRecord.activityDetailsList;
    this.activityDialog.show = true;
  }

  closeActivityDialog() {
    this.activityDetailsList = [];
    this.activityDialog.show = false;
  }

  public onSearchFilter() {
    this.resetOnScrollInput();
    this.advancedSearch.clearSearchModel();
    this.getBasicSearchFilterValues();
    if (this.isEnableF245991) {
      if (this.mainFilterData.globalFilter.searchType == Constants.My_Locked_Cases) {
        this.getMyLockCasesSearchFilterValues();
      } else {
        this.isValidMyLockCasesFilter = false;
      }
    }
    this.getWorkListData();
  }

  public getBasicSearchFilterValues() {
    this.mainFilterData.basicFilter = this.basicSearch.filterValues();    
    this.checkForGlobalRestriction();
    this.getGlobalSearchFilterValues();
  }

  public getGlobalSearchFilterValues() {
    this.mainFilterData.globalFilter = this.globalSearch?.filterValues();
  }

  public getMyLockCasesSearchFilterValues() {
    let basicSearch = this.basicSearch.filterValues();
    this.mainFilterData.basicFilter.inUse = basicSearch.inUse;
    this.mainFilterData.basicFilter.isIncludeClosed = basicSearch.isIncludeClosed;
    this.mainFilterData.basicFilter.isIncludeDeleted = basicSearch.isIncludeDeleted;
    if (this.basicSearch.getLoggedInUserId() == this.mainFilterData.basicFilter.inUse) {
      this.isValidMyLockCasesFilter = true;
    } else {
      this.isValidMyLockCasesFilter = false;
    }
  }

  public getAdvancedSearchFilterValues() {
    this.mainFilterData.advancedFilter = this.advancedSearch.filterValues();
    this.getBasicSearchFilterValues();
  }
  public setIsEnableF245991(key :string){
    this.workListService.getConfigSetting(key).subscribe(res=>{
      if(res && res === "true"){
          this.isEnableF245991= true;
      }
    });
  }
  public getWorkListData() {
    this.displayIndicator = true;
    this.ClearErrorMessagesSummary();
    if (!this.isValidSearch()) {
      this.tempData.data = [];
      this.workListTableData.data = [];
      this.recordCount = 0;
      this.displayIndicator = false;
      if (this.errorMessagesList?.length === 1) {
        this.notificationService.showError(this.errorMessagesList[0]);
      }
      else if (this.errorMessagesList?.length > 1) {
        this.setErrorMessagesSummary();
        this.notificationService.showError(this.errorMessage);
      }
      return;
    }
      this.fetchWorklistTableData().subscribe(res => {
        this.workListTableData.data = JSON.parse(JSON.stringify(res));
        console.log(this.workListTableData);
          if (this.pageReady) {
            this.displayIndicator = false;
          }
      });
  }

  private fetchWorklistTableData(): Observable<any> {
    const tableData = new Observable<any>(observer => {
      this.workListService.getWorkListData(this.mainFilterData).subscribe((res: any) => {
        this.tempData.data.push(...JSON.parse(JSON.stringify(res.data)));
        this.recordCount = res.recordCount;
        observer.next(this.tempData.data);
        observer.complete();
        this.changeDetect.detectChanges();
      },
        err => {
          console.log(err);
          this.displayIndicator = false;
          this.notificationService.showError("Unable to get worklist data");
        },
        () => { this.displayIndicator = false; });
    });
    return tableData;
  }

  public onClear() {
    this.globalSearch.ClearGlobalSearch();
    this.basicSearch.clearSearchModel();
    this.tempData.data = [];
    this.workListTableData.data = [];
    this.getGlobalSearchFilterValues();
    this.mainFilterData.skipCount = 0;
    this.ClearErrorMessages();
    this.getWorkListData();
  }

  public onScroll() {

    if (!this.scrollTriggered && this.workListTableData.data.length > 0 && (this.workListTableData.data.length % this.pageSize) === 0) {
      this.scrollTriggered = true;
      this.mainFilterData.skipCount = this.endIndex;
      this.mainFilterData.maxCount = this.pageSize;
      this.endIndex = this.mainFilterData.skipCount + this.pageSize;
      this.mainFilterData.sorting = this.sorting;
      this.getWorkListData();
      setTimeout(() => { this.scrollTriggered = false }, 500);
    }
  }

  public formatNotificationMessage(message , index){
    return message && message.length>0 && message?.includes("-") ? message?.Split('-')[index]: message;
  }

  public resetOnScrollInput() {
    this.tempData.data = [];
    this.mainFilterData.skipCount = 0;
    this.mainFilterData.maxCount = this.pageSize;
    this.endIndex = this.pageSize;
  }

  public openAssessmentInAppropriateWorkspace(worklistRecord: IWorkListModel) {

    const targetUrl = this.generateTargetUrlBasedOnUserRole(worklistRecord);

    this.workListService.GetCaseInUseAssessmentIds().subscribe(
      (assessmentsInUse: Array<number>) => {
        if (assessmentsInUse !== null && (assessmentsInUse.includes(worklistRecord.assessmentId) || assessmentsInUse.length < this.maxCaseInUseCount)) {
          this.showCaseInUseDialog=false;
          if(worklistRecord.inUse && !worklistRecord.isMatchCurrentUserandInUse){
          this.currentInUse=worklistRecord.inUse;
          this.showInUseWarningDialog=true;
          this.currentRecord=worklistRecord;
          return;
          
          }
          else {
            this.workListService.LockCase(worklistRecord.assessmentId).subscribe((res) =>
            {
              if (targetUrl && targetUrl.length > 0) {
                window.open(targetUrl);
              }
            });            
          return;
          }
          
          }
          this.showCaseInUseDialog = true;
      
      });
  }
  closeWarningDialog(): void {
    this.showInUseWarningDialog = false;
  }
  continueCase() {
    const targetUrl = this.generateTargetUrlBasedOnUserRole(this.currentRecord);
    this.showInUseWarningDialog=false;
    if (targetUrl && targetUrl.length > 0) {
      window.open(targetUrl);
      }
  }

  private generateTargetUrlBasedOnUserRole(worklistRecord: IWorkListModel): string {
    let targetUrl = "";
    if (this.caseOpenInCaseViewGranted) {
      targetUrl = [environment.caseViewUrl, 'CaseAdvisorAssessmentId', worklistRecord.assessmentId].join('/');
    }
    else if (this.caseOpenInCaseIntakeGranted) {
      // user is a intake user
      if (EditAssessmentPermittedStatuses.includes(worklistRecord.status) || ReadOnlyOpasStatuses.includes(worklistRecord.status)) {
        targetUrl = targetUrl = `${environment.caseIntakeUrl}/#/caseintake?assessmentId=${worklistRecord.assessmentId}&isAssessmentDraft=${worklistRecord.isAssessmentDraft ? 'true' : 'false'}&draftType=Edit Assessment`;
      } else {
        targetUrl = null;
      }
    } else {
      targetUrl = null;
    }

    return targetUrl;
  }

  public createNewCase() {
    const targetUrl = [environment.caseIntakeUrl, '#', 'caseintake',].join('/');
    let completeUrl = targetUrl + "?fromApp=" + "RMSWorklist";
    window.open(completeUrl);
  }

  public openChange($event: boolean) {
    this.openPanel = $event;
    window.localStorage.setItem(
      'searchPanelState',
      JSON.stringify($event)
    );
    if (!this.openPanel) {
      this.openadvancedPanel = false;
    }
  }

  public loadPersistedSearchPanel() {
    const searchPanelStateJson = window.localStorage.getItem('searchPanelState');
    if (searchPanelStateJson !== undefined && searchPanelStateJson !== null) {
      this.openPanel = JSON.parse(searchPanelStateJson) as boolean
    }
    else {
      this.openPanel = true;
    }
  }

  public loadPersistedAdvancedPanel() {
    const advancedPanelStateJson = window.localStorage.getItem('advancedPanelState');
    if (advancedPanelStateJson !== undefined && advancedPanelStateJson !== null) {
      this.openadvancedPanel = JSON.parse(advancedPanelStateJson) as boolean
    }
    else {
      this.openadvancedPanel = true;
    }
  }

  onGlobalSearchPanelClick($event) {
    this.openPanel = $event as boolean;
  }

  //Unlock Assessment
  showNormalDialog(assessmentid) {
    this.tempassessmentid = assessmentid;
    this.normalDialogModal.show = true;
  }

  closeDialog() {
    this.tempassessmentid = null;
    this.selectedUser = null;
    this.normalDialogModal.show = false;
  }

  //To get User Roles
  public getAllUserRoles() {
    this.workListService.getAllUserRoles().subscribe(
      (data: any) => {
        try {
          if (data && data.roles && data.roles.length > 0) {
            this.userRoles = data.roles;
          }
        }
        catch {

          this.notificationService.showError("Unable to get user roles");
        }

      }
    );
  }

  //To get User Identity Permissions
  public getUserIdentityRolesPermissions() : Promise<boolean>{
    return new Promise<boolean>((resolve, reject) => {
    this.displayIndicator = true;   
    this.workListService.getUserIdentityRolesPermissions().subscribe(
      (data: any) => {
        try {
          if (data && data.userPermissions && data.userPermissions.length > 0) {
            this.userRolesPermissions = data;
            this.userPermissions = data.userPermissions;
            this.unlockPermissionGranted = this.userPermissions.findIndex(x => x.name == Permissions.UnlockAssessment && x.isGranted) > -1 ? true : false;
            this.caseOpenInCaseViewGranted = this.userPermissions.findIndex(x => x.name == Permissions.CaseViewAccess && x.isGranted) > -1 ? true : false;
            this.checkForCaseIntakeGrantedAndMyNextReviewPremission();
            this.showPatientNameAsLink = this.showPatientNameAsHyperLink();
            this.pageReady = true;
            this.displayIndicator = false;
            this.hasWorklistAccess = this.checkForWorklistAccess();

            this.checkForUserOSPAPermission(data);
            this.checkForGlobalRestriction();
            this.getWorklistDataBasedonPermission(resolve);            
          }
          else{
            this.notificationService.showError("Please verify if you have appropriate permissions to access Worklist.");     
              resolve(false);
          }          
        }
        catch (error){
          this.notificationService.showError("Unable to get user permissions");
          this.displayIndicator = false;
          reject(error);
        }
    });
  });
}

    private checkForCaseIntakeGrantedAndMyNextReviewPremission() {
        this.caseOpenInCaseIntakeGranted = this.userPermissions.findIndex(x => x.name == Permissions.ConcurrentIntake && x.isGranted) > -1 ? true : false;
        this.hasMyNextReviewAccess = this.userPermissions.findIndex(x => x.name == Permissions.MyNextReview && x.isGranted) > -1 ? true : false;
    }

  private showPatientNameAsHyperLink() {
    return this.caseOpenInCaseViewGranted || this.caseOpenInCaseIntakeGranted;
  }
  private checkForWorklistAccess() {
    return this.caseOpenInCaseIntakeGranted || this.caseOpenInCaseViewGranted || this.hasMyNextReviewAccess || this.unlockPermissionGranted;
  }
    private getWorklistDataBasedonPermission(resolve: (value: boolean | PromiseLike<boolean>) => void) {
        if (this.hasWorklistAccess) {
            this.getWorkListData();
            resolve(true);
        }
        else {
            this.notificationService.showError("Please verify if you have appropriate permissions to access Worklist.");
            resolve(false);
        }
    }

  private checkForUserOSPAPermission(data: any) {
        if (data.userPermissions.filter(x => x.name === Permissions.OspaPermission).length > 0) {
            this.checkIsUserOSPA();
        }
    }

  public checkForGlobalRestriction() {
    if(this.userRolesPermissions && this.userRolesPermissions.userPermissions && this.userRolesPermissions.userPermissions.length > 0)
    {
      if(this.userRolesPermissions.userPermissions.some(up => up.name.endsWith(Constants.Global_Restriction_Role_Name) && up.isGranted == true ))
      {
        this.mainFilterData.basicFilter.isGlobalRestriction = true;
      }
      if(this.userRolesPermissions.userPermissions.some(up => up.name.endsWith(Constants.Global_Restriction_PR_Role_Name) && up.isGranted == true ))
      {
        this.mainFilterData.basicFilter.isGlobalRestrictionPR = true;
      }
    }
  }

  public checkIsUserOSPA() {    
    this.workListService.getUserPermissionForOSPAToggle().subscribe(
      (res: boolean) => {
        try {
          if (res != null) {
            this.showIsAcceptingCasesToggle = res;
          };
        }
        catch {
          this.notificationService.showError("Unable to get user permission for OSPA toggle");
        }
      });
  }

  public LoadAssignableUsers() {

    this.workListService.getAssignableUsers().subscribe(
      (res: IAssignableUsers) => {
        try {
          if (res && res.data && res.data.length > 0) {
            res.data.forEach((value: StringSelectionModel, indexNumber: number, array: any[]) => {
              this.assignableUsers.push(value);
            });
          }
        }
        catch {
          this.notificationService.showError("Unable to get assignable users");
        }
      }
    );
  }

  //Unlock Assessment click
  public unlockAssessment() {
    let assignToUser = "";
    let assignedToName="";
    if (this.selectedUser !== undefined && this.selectedUser !== null) {
      assignToUser = this.selectedUser.value;
      assignedToName=this.selectedUser.label;
    }

    this.workListService.unlockAssessment(this.tempassessmentid, assignToUser).subscribe(
      (data: any) => {
        try {
          const caseInUseModel: CaseInUseOutputModel = data;
          if (caseInUseModel.isSuccess) {
            const index = this.workListTableData.data.findIndex(x => x.assessmentId == this.tempassessmentid);
            this.workListTableData.data[index].inUse = null;
            this.workListTableData.data[index].assignedToName=assignedToName;
            this.normalDialogModal.show = false;
            this.notificationService.showSuccess("Case Unlocked Successfully");
            this.onSearchFilter();
          }

        }
        catch {
          this.notificationService.showError("Case Unlock Failed");
        }
        finally {
          this.selectedUser = null;
          this.normalDialogModal.show = false;
        }

      }
    );
  }

  public closeShowCaseInUseDialog() {
    this.showCaseInUseDialog = false;
  }

  public onGlobalSearchClick() {
    this.resetOnScrollInput();
    this.advancedSearch.clearSearchModel();
    this.basicSearch.clearSearchModel();
    this.tempData.data = [];
    this.workListTableData.data = [];
    this.getGlobalSearchFilterValues();
    if (this.isEnableF245991) {
      if (this.mainFilterData.globalFilter.searchType == Constants.My_Locked_Cases) {
        this.getMyLockCasesSearchFilterValues();
      } else {
        this.mainFilterData.basicFilter.inUse = '';
        this.mainFilterData.basicFilter.isIncludeClosed = false;
        this.mainFilterData.basicFilter.isIncludeDeleted = false;
        this.isValidMyLockCasesFilter = false;
      }
    }
    this.getWorkListData();
  }

  public onGlobalClear() {
    this.globalSearch.ClearGlobalSearch();
    this.tempData.data = [];
    this.workListTableData.data = [];
    this.globalSearch.setInitialFilter();
    this.getGlobalSearchFilterValues();
    this.mainFilterData.skipCount = 0;
    if (this.isEnableF245991) {
      this.mainFilterData.basicFilter.inUse = '';
      this.mainFilterData.basicFilter.isIncludeClosed = false;
      this.mainFilterData.basicFilter.isIncludeDeleted = false;
      this.isValidMyLockCasesFilter = false;
    }
    this.getWorkListData();
  }

  public onGlobalButtonsPanelClick($event) {
    const searchPanelStateJson = window.localStorage.getItem('searchPanelState');
    if (searchPanelStateJson !== undefined && searchPanelStateJson !== null) {
      this.openPanel = !JSON.parse(searchPanelStateJson) as boolean
    }
    else {
      this.openPanel = !true;
    }
  }

  public openAdvancedChange($event) {
    this.openadvancedPanel = $event;
    window.localStorage.setItem(
      'advancedPanelState',
      JSON.stringify($event)
    );
  }

  public onAdvancedSearchClick() {
    this.resetOnScrollInput();
    this.getAdvancedSearchFilterValues();
    if (this.isEnableF245991) {
      if (this.mainFilterData.globalFilter.searchType == Constants.My_Locked_Cases) {
        this.getMyLockCasesSearchFilterValues();
      } else {
        this.isValidMyLockCasesFilter = false;
      }
    }
    this.getWorkListData();
  }

  public onAdvancedSearchClear() {
    this.advancedSearch.clearSearchModel();
    this.globalSearch.ClearGlobalSearch();
    this.basicSearch.clearSearchModel();
    this.tempData.data = [];
    this.workListTableData.data = [];
    this.getGlobalSearchFilterValues();
    this.mainFilterData.skipCount = 0;
    this.getWorkListData();
  }

  public onAdvancedSearchPanelClick($event) {
    const panelStateJson = window.localStorage.getItem('advancedPanelState');
    if (panelStateJson !== undefined && panelStateJson !== null) {
      this.openadvancedPanel = !JSON.parse(panelStateJson) as boolean
    }
    else {
      this.openadvancedPanel = false;
    }
  }

  onSortChange(sortState: IUITKColumnState) {
    const { column, direction } = sortState;
    // sort data
    this.onSortColumn(column, direction);
    // Update table state with sort state
    this.workListTableData.setSortState({ column, direction });
  }

  onSortColumn(column: string, direction: UITKSortDirection) {
   
    this.sorting = `${column} ${direction === UITKSortDirection.DESC ? 'DESC' : 'ASC'}`;
    this.mainFilterData.sorting = this.sorting;
    this.resetOnScrollInput();
    this.getWorkListData();
  }

  public setErrorMessagesSummary() {
    const ulOpenTag = "<ul>";
    const ulCloseTag = "</ul>";
    const liOpenTag = "<li>";
    const liCloseTag = "</li>";

      this.errorMessage += ulOpenTag;
      for (let i = 0; i < this.errorMessagesList?.length; i++) {
        this.errorMessage += liOpenTag;
        this.errorMessage += this.errorMessagesList[i];
        this.errorMessage += liCloseTag;
      }
      this.errorMessage += ulCloseTag;
  }

  public isValidSearch(): boolean {

    const assmtIdLen = this.mainFilterData?.globalFilter?.searchField?.toString()?.length;
    this.checkForAssmntIdLength(assmtIdLen);
    if (this.mainFilterData?.globalFilter?.searchField?.toString() == "0") {
      this.errorMessagesList.push("Assessment Id is Invalid");
    }
    const acctNumLenth = this.mainFilterData?.basicFilter?.accountNumber?.length;
    this.checkForAccountNumberLength(acctNumLenth);

    this.validateDateRanges();

    if (this.errorMessagesList.length > 0) {
      return false;
    }
    else if (this.mainFilterData?.globalFilter?.searchType == Constants.My_Locked_Cases && !this.isValidMyLockCasesFilter)
    {
      return false;
    }
    else {
      return true;
    }
  }

    private checkForAccountNumberLength(acctNumLenth: number) {
        if (acctNumLenth > 0 && acctNumLenth < 4) {
            this.errorMessagesList.push("Account Number requires a minimum of four characters");
        }
    }

    private validateDateRanges() {
        if (this.globalSearch?.validateCreatedDateRange()) {
            this.errorMessagesList.push("Created Date range is Invalid");
        }

        if (this.basicSearch?.validateAdmitDateRange()) {
            this.errorMessagesList.push("Admit Date range is Invalid");
        }

        if (this.basicSearch?.validateDischargeDateRange()) {
            this.errorMessagesList.push("Discharge Date range is Invalid");
        }
    }

    private checkForAssmntIdLength(assmtIdLen: number) {
        if (assmtIdLen > 9) {
            this.errorMessagesList.push("Assessment Id allows maximum of 9 digits");
        }
    }

  private ClearErrorMessagesSummary() {
    this.errorMessage = "";
    this.errorMessagesList = [];
  }

  private ClearErrorMessages() {
    this.notificationService.hideErrors();
    this.ClearErrorMessagesSummary();
    this.displayIndicator = false;
  }

  public MyNextCase() {
    this.mnrButtonEnabled = false; 
    this.workListService.GetCaseInUseAssessmentIds().subscribe(
      (assessmentsInUse: Array<number>) => {
        if (assessmentsInUse !== null && assessmentsInUse.length < this.maxCaseInUseCount) {
          this.OpenMyNextCase();
        }
        else {
          this.showCaseInUseDialog = true;
          this.mnrButtonEnabled = true;
        }
      });
  }

  private OpenMyNextCase() {
    this.dispenseAssessmentModel = { RecentReviewTimeSpan: this.recentReviewTimeSpan, userRolesPermissions: this.userRolesPermissions };
    this.workListService.GetMyNextCase(this.dispenseAssessmentModel).subscribe(
      (assessmentIds: Array<number>) => {
        if (assessmentIds !== null && assessmentIds.length > 0) {
          this.showCaseInUseDialog = false;
          const firstAssessmentId = assessmentIds[0];
          const targetUrl = [environment.caseViewUrl, 'CaseAdvisorAssessmentId', firstAssessmentId].join('/');
          window.open(targetUrl);

          if (assessmentIds.length > 1) {
            setTimeout(() => {
              assessmentIds.slice(1).forEach((assessmentId: number) => {
                if (assessmentId > 0) {
                  const targetUrl = [environment.caseViewUrl, 'CaseAdvisorAssessmentId', assessmentId].join('/');
                  window.open(targetUrl);
                }
              });
            }, 2000);
           }
          }
        this.mnrButtonEnabled = true;
      }
    );
  }

  public handleisAcceptingCasesToggleChange(checked) {
    this.isAcceptingCases = checked;
    this.workListService.UpdateAcceptingCases(checked).subscribe(
      (data: boolean) => {
        try {
          if (!data) {
            this.notificationService.showError("Unable to save isAcceptingCases for current user");
            this.isAcceptingCases = !checked;
          }
        }
        catch {
          this.isAcceptingCases = !checked;
          this.notificationService.showError("Unable to save isAcceptingCases for current user");
        }
      });
  }

  public slrNotificationFormat(notificationMsg: string, index: number){
    return notificationMsg?.length > 0 && notificationMsg?.includes('-') ? notificationMsg?.split('-')[index]: notificationMsg;
  }

  public retrieveIsCurrentUserAcceptingCases() {
    this.workListService.IsCurrentUserAcceptingCases().subscribe(
      (res: boolean) => {
        try {
          this.isAcceptingCases = res;
        }
        catch {
          this.notificationService.showError("Unable to get isAcceptingCases for current user");
        }
      });
  }
}
